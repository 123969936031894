import React, { ReactElement } from "react";
import * as DjangoRender from "@django-render/core";

import LoginView from "./auth/views/Login";
import UserProfileView from "./users/views/Profile";
import ManuscriptAfterCreateView from "./manuscripts/views/ManuscriptAfterCreate";
import ManuscriptListView from "./manuscripts/views/ManuscriptList";
import ManuscriptDetailView from "./manuscripts/views/ManuscriptDetail";
import TeamListView from "./teams/views/TeamList";
import FormView from "./common/views/Form";
import ConfirmDeleteView from "./common/views/ConfirmDelete";
import NotFoundView from "./common/views/NotFound";
import HelpView from "./common/views/Help";

import FieldDef from "./forms/adapters/Field";
import FormDef from "./forms/adapters/Form";
import ServerRenderedInputtDef from "./forms/adapters/widgets/ServerRenderedInput";
import TextInputDef from "./forms/adapters/widgets/TextInput";
import FileInputDef from "./forms/adapters/widgets/FileInput";
import AutoCompleteSelectDef from "./forms/adapters/widgets/Autocomplete";
import AutoCompleteSelectMultipleDef from "./forms/adapters/widgets/AutocompleteSelectMultiple";
import SearchCompetitorsDef from "./forms/adapters/widgets/SearchCompetitors";

import "./App.css";
import {
  CSRFTokenContext,
  URLsContext,
  UserContext,
  CurrentWorkspaceContext,
  WorkspacesContext,
} from "./contexts";
import CompetitorAfterCreateView from "./manuscripts/views/ManuscriptAfterCreate/CompetitorAfterCreate";
import SubmittableListView from "./submittable/views/SubmittableList";
import SubmittableFormsView from "./submittable/views/SubmittableForms";

const config = new DjangoRender.Config();
config.addView("auth-login", LoginView);
config.addView("user-profile", UserProfileView);
config.addView("manuscript-after-create", ManuscriptAfterCreateView);
config.addView("competitor-after-create", CompetitorAfterCreateView);
config.addView("manuscript-list", ManuscriptListView);
config.addView("manuscript-detail", ManuscriptDetailView);
config.addView("team-list", TeamListView);
config.addView("submittable-list", SubmittableListView);
config.addView("submittable-form-list", SubmittableFormsView);
config.addView("common-form", FormView);
config.addView("common-confirm-delete", ConfirmDeleteView);
config.addView("not-found", NotFoundView);
config.addView("help", HelpView);

config.addContextProvider("user", UserContext);
config.addContextProvider("csrf_token", CSRFTokenContext);
config.addContextProvider("urls", URLsContext);
config.addContextProvider("current_workspace", CurrentWorkspaceContext);
config.addContextProvider("workspaces", WorkspacesContext);

config.addAdapter("forms.Form", FormDef);
config.addAdapter("forms.Field", FieldDef);
config.addAdapter("forms.ServerRenderedInput", ServerRenderedInputtDef);
config.addAdapter("forms.TextInput", TextInputDef);
config.addAdapter("forms.FileInput", FileInputDef);
config.addAdapter("forms.Select", AutoCompleteSelectDef);
config.addAdapter("forms.SelectMultiple", AutoCompleteSelectMultipleDef);
config.addAdapter("forms.SearchCompetitors", SearchCompetitorsDef);
config.addAdapter("Date", Date);

function App(): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const initialResponse = JSON.parse(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById("initial-response")!.textContent!,
  );

  return (
    <DjangoRender.App
      config={config}
      initialResponse={initialResponse as JSON}
    />
  );
}

export default App;
