import React, { ReactElement, SyntheticEvent } from "react";
import { FormWidgetChangeNotificationContext } from "@django-render/core";
import { AutocompleteSelectMultiple } from "@harpercollins/harpercollins-design-system";
import { AutocompleteProps } from "@mui/joy";
import { WidgetDef } from "./base";
import { BaseOption } from "../../../manuscripts/types";

interface AutocompleteSelectMultipleProps<T extends BaseOption>
  extends AutocompleteProps<T, boolean, boolean, boolean> {
  id: string;
  name: string;
  disabled: boolean;
  className: string;
  onChange?: (event: SyntheticEvent<Element, Event>) => void;
  options: T[];
  defaultValue: T[];
  label?: string;
  inline?: boolean;
}

function AutoCompleteMultipleWrapper<T extends BaseOption>({
  onChange: originalOnChange,
  options,
  ...props
}: AutocompleteSelectMultipleProps<T>) {
  const changeNotification = React.useContext(
    FormWidgetChangeNotificationContext,
  );
  return (
    <AutocompleteSelectMultiple
      {...props}
      options={options}
      onChange={(event: SyntheticEvent<Element, Event>) => {
        if (originalOnChange) {
          originalOnChange(event);
        }
        if (changeNotification) {
          changeNotification();
        }
      }}
    />
  );
}
export default class SelectMultipleDef implements WidgetDef {
  idForLabel: string;

  choices: BaseOption[];

  className: string;

  inline: boolean;

  constructor(
    idForLabel: string,
    choices: BaseOption[],
    className: string,
    inline: boolean,
  ) {
    this.idForLabel = idForLabel;
    this.choices = choices;
    this.className = className;
    this.inline = inline;
  }

  static contextType = FormWidgetChangeNotificationContext;

  context!: () => void;

  render(
    id: string,
    name: string,
    disabled: boolean,
    value: string,
  ): ReactElement {
    return (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <AutoCompleteMultipleWrapper
        id={id}
        name={name}
        defaultValue={value as unknown as BaseOption[]}
        options={this.choices}
        disabled={disabled}
        className={this.className}
        inline={this.inline}
      />
    );
  }

  getIdForLabel(id: string): string {
    return this.idForLabel.replace("__ID__", id);
  }
}
