import {
  formatCurrency,
  formatDate,
  formatNumber,
  formatText,
  RelativeTime,
} from "@harpercollins/harpercollins-design-system";
import { CellContext } from "@tanstack/react-table";
import styled from "styled-components";
import ScoreBadge from "../../../manuscripts/views/components/ScoreBadge";
import { trafficLightRanges } from "../../../constants";
import Status from "../../../manuscripts/views/components/Status";
import { Analysis } from "../../../manuscripts/types";
import InfoTooltip from "../InfoTooltip";

const StyledHeaderCell = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing--xsmall);
`;

export function HeaderCell({
  label,
  tooltip,
}: {
  label: string;
  tooltip?: string;
}) {
  if (tooltip) {
    return (
      <StyledHeaderCell>
        <div>{label}</div>
        <InfoTooltip title={tooltip} />
      </StyledHeaderCell>
    );
  }
  return <span>{label}</span>;
}

export function DateCell<TData, TValue>({
  getValue,
}: CellContext<TData, TValue>) {
  const value = getValue() as string;
  return <span title={formatDate(value)}>{formatDate(value)}</span>;
}

export function NumberCell<TData, TValue>({
  getValue,
}: CellContext<TData, TValue>) {
  const value = getValue() as string;
  return <span title={formatNumber(value)}>{formatNumber(value)}</span>;
}

export function CurrencyCell<TData, TValue>({
  getValue,
}: CellContext<TData, TValue>) {
  const value = getValue() as string;
  return <span title={formatCurrency(value)}>{formatCurrency(value)}</span>;
}

export function TextCell<TData, TValue>({
  getValue,
}: CellContext<TData, TValue>) {
  const value = getValue() as string;
  return <span title={formatText(value)}>{formatText(value)}</span>;
}
export function RelativeTimeCell<TData, TValue>({
  getValue,
}: CellContext<TData, TValue>) {
  const value = getValue();
  // FIXME: This is a hack to handle null values
  if (value === null) {
    return <span>-</span>;
  }
  return <RelativeTime time={new Date(value as string)} />;
}

export function AnalysisScoreCell<TData, TValue>({
  getValue,
}: CellContext<TData, TValue>) {
  const value = getValue() as number;
  return (
    <ScoreBadge
      trafficLightRanges={trafficLightRanges.score}
      value={value}
      format="percentage"
      size="small"
      basic
    />
  );
}

export function PotentialCommercialSuccessCell<TData, TValue>({
  getValue,
}: CellContext<TData, TValue>) {
  const value = getValue() as number;
  return (
    <ScoreBadge
      trafficLightRanges={trafficLightRanges.score}
      value={value}
      format="percentage"
      size="small"
      basic
    />
  );
}

export function StatusCell<TData, TValue>({
  getValue,
}: CellContext<TData, TValue>) {
  const value = getValue() as Analysis["status"]["code"];
  return <Status status={value} />;
}
