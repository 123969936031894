import React, { ReactNode, useRef } from "react";
import {
  AppBar,
  Divider,
  Icon,
  Menu,
  MenuHeading,
  MenuItem,
} from "@harpercollins/harpercollins-design-system";
import styled from "styled-components";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { Link, NavigationContext } from "@django-render/core";

import {
  CSRFTokenContext,
  URLsContext,
  CurrentWorkspaceContext,
  WorkspacesContext,
} from "../../../contexts";
import ModalWindow from "../ModalWindow";

const BetaLabel = styled.span`
  background-color: rgba(255, 255, 255, 0.14);
  color: white;
  font-size: var(--font-size--xxsmall);
  font-weight: var(--font-weight--bold);
  text-transform: uppercase;
  padding: var(--spacing--xxxsmall) var(--spacing--xxsmall);
  border-radius: var(--border-radius--small);
  margin-left: var(--spacing--xsmall);
  line-height: inherit;
`;

function CurrentApp() {
  return (
    <>
      ScriptScan <BetaLabel>Beta</BetaLabel>
    </>
  );
}

function Apps() {
  return (
    <Menu placement="bottom-start">
      <MenuHeading sticky>HarperCollins Apps</MenuHeading>
      <MenuItem>
        <a
          href="https://harperhome.harpercollins.co.uk/"
          target="_blank"
          rel="noreferrer"
        >
          HarperHome
        </a>
      </MenuItem>
      <MenuItem>
        <a
          href="https://harpercollins-horizon.hcsupplychain.com/"
          target="_blank"
          rel="noreferrer"
        >
          Horizon
        </a>
      </MenuItem>
      <MenuItem selected>ScriptScan</MenuItem>
      <MenuHeading sticky>Other Apps</MenuHeading>
      <MenuItem>
        <a href="https://newscorp.okta.com/" target="_blank" rel="noreferrer">
          Okta
        </a>
      </MenuItem>
    </Menu>
  );
}

function MyAccountMenu() {
  const { openOverlay } = React.useContext(NavigationContext);
  const urls = React.useContext(URLsContext);

  const csrfToken = React.useContext(CSRFTokenContext);
  const renderModalWindow = React.useCallback(
    (content: ReactNode) => <ModalWindow side="right">{content}</ModalWindow>,
    [],
  );
  const formRef = useRef<HTMLFormElement>(null);
  const handleClickSignOut = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault();
    if (formRef.current) {
      formRef.current.submit();
    }
  };
  return (
    <Menu placement="bottom-end">
      <MenuHeading sticky>My Account</MenuHeading>
      {urls.auth_user_preferences !== null && (
        <MenuItem>
          <button
            type="button"
            onClick={() =>
              openOverlay(
                urls.auth_user_preferences as string,
                renderModalWindow,
              )
            }
          >
            Preferences
          </button>
        </MenuItem>
      )}
      {urls.auth_change_password !== null && (
        <MenuItem>
          <button
            type="button"
            onClick={() =>
              openOverlay(
                urls.auth_change_password as string,
                renderModalWindow,
              )
            }
          >
            Change password
          </button>
        </MenuItem>
      )}
      <Divider />
      {/* HACK: MenuItem onClick prevents form submission so handling submit with ref */}
      <MenuItem onClick={(e) => handleClickSignOut(e)}>
        <form ref={formRef} action={urls.auth_logout} method="post">
          <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
          <button type="submit">Sign out</button>
        </form>
      </MenuItem>
    </Menu>
  );
}

function ScriptScanAppBar() {
  const workspaces = React.useContext(WorkspacesContext);
  const currentWorkspace = React.useContext(CurrentWorkspaceContext);
  const urls = React.useContext(URLsContext);

  return (
    <AppBar
      currentApp={<CurrentApp />}
      apps={<Apps />}
      workspaces={workspaces}
      workspacesMenu={
        <>
          {workspaces.map((workspace) => (
            <MenuItem key={workspace.name}>
              <div>
                <a href={workspace.home_url}>{workspace.name}</a>
              </div>

              {workspace.is_admin && (
                <div className="settings">
                  <Link
                    href={workspace.settings_url}
                    aria-label="Workspace Settings"
                  >
                    <Icon icon={faGear} size="medium" />
                  </Link>
                </div>
              )}
            </MenuItem>
          ))}
        </>
      }
      helpButton={{
        as: Link,
        href: urls.help,
        text: "Help",
      }}
      currentWorkspace={currentWorkspace?.name || ""}
      myAccountMenu={<MyAccountMenu />}
    />
  );
}
export default ScriptScanAppBar;
