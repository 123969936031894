// eslint-disable-next-line max-classes-per-file
export function tokenise(text: string): string[] {
  // First remove punctuation, then convert to lowercase, then split on whitespace.
  return text
    .replace(/[^\w\s']|_/g, "")
    .replace(/\s+/g, " ")
    .toLowerCase()
    .split(" ");
}

export function leftNGrams(
  token: string,
  minLength: number,
  maxLength: number,
): string[] {
  // Generate a list of prefixes for the given token
  // For example: leftNgrams("world", 1, 5) => ["w", "wo", "wor", "worl", "world"]
  const nGrams: string[] = [];
  for (let length = minLength; length <= maxLength; length += 1) {
    if (length > token.length) {
      break;
    }
    nGrams.push(token.slice(0, length));
  }
  return nGrams;
}

export class SearchVector {
  terms: string[];

  prefixes: string[];

  constructor(text: string) {
    this.terms = tokenise(text);
    this.prefixes = [];
    this.terms.forEach((term) =>
      leftNGrams(term, 1, 10).forEach((nGram) => {
        if (this.prefixes.indexOf(nGram) < 0) {
          this.prefixes.push(nGram);
        }
      }),
    );
  }
}

export class SearchQuery {
  terms: string[];

  lastTerm: string | null;

  constructor(text: string) {
    this.terms = tokenise(text);
    this.lastTerm = this.terms[this.terms.length - 1];
  }

  score(vector: SearchVector): number {
    // Find number of full terms the search vectors have in common
    let score = this.terms.filter(
      (term) => vector.terms.indexOf(term) >= 0,
    ).length;

    // Add a half point if the last term matches any prefix
    if (this.lastTerm && vector.prefixes.indexOf(this.lastTerm) >= 0) {
      score += 0.5;
    }

    return score;
  }
}
