import { styled } from "styled-components";
import { Heading, TextBlock } from "@harpercollins/harpercollins-design-system";
import ScoreBadge from "../../components/ScoreBadge";
import { DetailedAnalysisResult } from "../../../types";
import { trafficLightRanges } from "../../../../constants";
import { getTrafficLightText } from "../../../../utils/utils";
import { MatchText } from "../Plagiarism/ExpandableTable";

interface AIProps {
  ai: DetailedAnalysisResult["originality"]["ai"];
}

const Wrapper = styled.div`
  width: 100%;
`;

const AISummary = styled.div`
  background-color: var(--color--grey-lightest);
  padding: var(--spacing--medium);
  border-radius: var(--border-radius--small);
  margin-bottom: var(--spacing--large);
`;

const AIHeading = styled.div`
  display: flex;
  align-items: baseline;
  h2 {
    margin-bottom: 0;
  }
`;

const Block = styled.div`
  display: flex;
  margin-bottom: var(--spacing--medium);
  padding-bottom: var(--spacing--medium);
  border-bottom: 1px solid var(--color--grey-borders);
`;

function AI({ ai }: AIProps) {
  if (!ai) {
    return <div>AI free report not available.</div>;
  }
  return (
    <Wrapper>
      <AISummary>
        <AIHeading>
          <ScoreBadge
            value={Math.round(ai?.ai_score)}
            format="percentage"
            trafficLightRanges={trafficLightRanges.aiFree}
          />
          <p>
            {getTrafficLightText(
              Math.round(ai?.ai_score),
              trafficLightRanges.aiFree,
            )}
          </p>
        </AIHeading>
      </AISummary>
      {Array.isArray(ai?.blocks) && ai.blocks.length > 0 ? (
        <>
          <Heading as="h2">Flagged Blocks</Heading>
          <p>The following extracts could have been generated by AI:</p>
          {ai.blocks.map((block, index: number) => (
            <Block key={block.text}>
              <ScoreBadge
                value={Math.round(100 * block.fake)}
                format="percentage"
                trafficLightRanges={trafficLightRanges.aiFree}
              />
              <TextBlock
                label={`Block ${index + 1}`}
                content={
                  <MatchText>
                    <span>{block.text}</span>
                  </MatchText>
                }
                size="medium"
              />
            </Block>
          ))}
        </>
      ) : (
        <div>No AI written blocks of text found</div>
      )}
    </Wrapper>
  );
}

export default AI;
