import styled from "styled-components";

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacing--medium);
`;

export const HeaderTitle = styled.div<{ $centered?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.$centered ? "column" : "row")};
  align-items: ${(props) => (props.$centered ? "center" : "flex-start")};
  justify-content: ${(props) => (props.$centered ? "center" : "flex-start")};
  width: 100%;
  && h1 {
    margin-bottom: 0;
  }
`;

export const HeaderContent = styled.div<{ $centered?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.$centered ? "center" : "flex-start")};
  justify-content: ${(props) => (props.$centered ? "center" : "flex-start")};
  text-align: ${(props) => (props.$centered ? "center" : "left")};
  gap: var(--spacing--medium);
  width: 100%;
  max-width: ${(props) =>
    props.$centered ? "var(--breakpoint--xlarge)" : "100%"};
  margin: 0 auto;

  p {
    max-width: var(--breakpoint--xxlarge);
    margin-bottom: 1rem;
  }

  @media print {
    text-align: left;
  }
`;

export const HeaderCommands = styled.div`
  margin-left: auto;
  margin-bottom: var(--spacing--medium);
  display: flex;
  gap: var(--spacing--small);
`;

export default HeaderWrapper;
