import { Ref, useEffect, useRef } from "react";

// @see https://github.com/facebook/react/issues/24722
export default function useForwardRef<T>(forwardedRef: Ref<T>) {
  // final ref that will share value with forward ref. this is the one we will attach to components
  const innerRef = useRef<T>(null);

  useEffect(() => {
    // try to share current ref value with forwarded ref
    if (!forwardedRef) {
      return;
    }
    if (typeof forwardedRef === "function") {
      forwardedRef(innerRef.current);
    } else {
      // by default forwardedRef.current is readonly
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      forwardedRef.current = innerRef.current;
    }
  }, [forwardedRef]);

  return innerRef;
}
