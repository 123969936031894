import React from "react";
import { User } from "./users/types";

export const UserContext = React.createContext<User | null>(null);

export interface URLs {
  manuscript_list: string;
  manuscript_create: string;
  team_list: string;
  team_create: string;
  submittable_list: string;
  submittable_create: string;
  auth_logout: string;
  auth_change_password: string | null;
  auth_user_preferences: string | null;
  competitor_search: string;
  help: string;
}

export const URLsContext = React.createContext<URLs>({
  manuscript_list: "",
  manuscript_create: "",
  team_list: "",
  team_create: "",
  submittable_list: "",
  submittable_create: "",
  auth_logout: "",
  auth_change_password: null,
  auth_user_preferences: null,
  competitor_search: "",
  help: "",
});

export const CSRFTokenContext = React.createContext<string>("");

interface Workspace {
  name: string;
  home_url: string;
  settings_url: string;
  is_admin: boolean;
}

export const CurrentWorkspaceContext = React.createContext<Workspace | null>(
  null,
);

export const WorkspacesContext = React.createContext<Workspace[]>([]);
