import { styled } from "styled-components";
import {
  Icon,
  ProgressCircle,
  Tooltip,
} from "@harpercollins/harpercollins-design-system";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { TrafficLightRanges } from "../../../types";

interface KPIProps {
  name: string;
  percentage: number | null | undefined;
  tooltip?: string;
  trafficLightRanges: TrafficLightRanges;
}

const StyledKPI = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing--small);
  width: 100%;
  height: 100%;
  margin: var(--spacing--small) auto var(--spacing--large) auto;
  h2 {
    font-size: var(--font-size--medium);
    font-weight: var(--font-weight--bold);
  }
`;

function KPI({ name, percentage, tooltip, trafficLightRanges }: KPIProps) {
  return (
    <StyledKPI>
      {name ? (
        <h2>
          {name}{" "}
          {tooltip ? (
            <Tooltip placement="top" title={tooltip}>
              <Icon icon={faInfoCircle} colour="grey" size="small" />
            </Tooltip>
          ) : null}
        </h2>
      ) : null}
      <ProgressCircle
        value={percentage}
        kind="traffic-light"
        trafficLightRanges={trafficLightRanges}
      />
    </StyledKPI>
  );
}

export default KPI;
