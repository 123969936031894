import { useState, useEffect } from "react";
import {
  Badge,
  Icon,
  Tooltip,
} from "@harpercollins/harpercollins-design-system";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

interface ExpiryCountdownProps {
  expiryDate: Date;
}

interface TimeRemaining {
  days: number;
  hours: number;
  minutes: number;
}

function calculateTimeRemaining(expiryDate: Date): TimeRemaining {
  const now = new Date().getTime();
  const expiration = expiryDate.getTime();
  const timeDifference = expiration - now;

  if (timeDifference <= 0) {
    return { days: 0, hours: 0, minutes: 0 };
  }

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  return { days, hours, minutes };
}

function ExpiryTooltip() {
  return (
    <Tooltip
      placement="top"
      title="Time until this analysis is deleted for legal compliance"
    >
      <Icon icon={faInfoCircle} size="small" colour="grey" />
    </Tooltip>
  );
}

function ExpiryCountdown({ expiryDate }: ExpiryCountdownProps) {
  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>(
    calculateTimeRemaining(expiryDate),
  );

  useEffect(() => {
    const oneMinute = 1000 * 60; // one minute
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(expiryDate));
    }, oneMinute);

    return () => {
      clearInterval(interval);
    };
  }, [expiryDate]);

  // display expired message
  if (timeRemaining.days === 0 && timeRemaining.hours === 0) {
    return (
      <Badge kind="danger">
        <time
          dateTime={expiryDate.toISOString()}
          title={expiryDate.toLocaleString()}
        >
          Expired
        </time>
      </Badge>
    );
  }
  // display time remaining
  return timeRemaining.days === 0 ? (
    <Badge kind="danger" size="small">
      <time
        dateTime={expiryDate.toISOString()}
        title={expiryDate.toLocaleString()}
      >
        {" "}
        {timeRemaining.hours > 0 && (
          <>
            Expires in {timeRemaining.hours} hrs{" "}
            {timeRemaining.minutes > 0 && timeRemaining.minutes} mins
          </>
        )}
      </time>
      <ExpiryTooltip />
    </Badge>
  ) : (
    <Badge kind={timeRemaining.days <= 7 ? "warning" : "default"} size="small">
      <time
        dateTime={expiryDate.toISOString()}
        title={expiryDate.toLocaleString()}
      >
        Expires in {timeRemaining.days} days
      </time>{" "}
      <ExpiryTooltip />
    </Badge>
  );
}

export default ExpiryCountdown;
