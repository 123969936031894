import { TrafficLights, TrafficLightsExtended } from "./manuscripts/types";

const constants: {
  NULL_DISPLAY_VALUE: string;
} = {
  NULL_DISPLAY_VALUE: "-",
};

export const trafficLightRanges: {
  aiFree: TrafficLights;
  readability: TrafficLights;
  score: TrafficLightsExtended;
  plagiarismFree: TrafficLights;
} = {
  aiFree: {
    green: {
      min: 80,
      max: 100,
      text: "There is significant evidence to suggest that this manuscript was created by a human.",
    },
    orange: {
      min: 40,
      max: 79,
      text: "There's not enough statistical confidence to suggest that this manuscript was written by a human or by AI",
    },
    red: {
      min: 0,
      max: 39,
      text: "There is significant evidence to suggest that substantial portions of this manuscript were generated by AI.",
    },
  },
  plagiarismFree: {
    green: {
      min: 80,
      max: 100,
      text: "This manuscript exhibits no signs or negligible levels of plagiarism.",
    },
    orange: {
      min: 60,
      max: 79,
      text: "The manuscript reveals concerning levels of plagiarised content. Please refer to the provided table for details on the sources found.",
    },
    red: {
      min: 0,
      max: 59,
      text: "This manuscript is heavily plagued with plagiarised content. Details on the sources are provided in the accompanying table.",
    },
  },
  readability: {
    green: {
      min: 80,
      max: 100,
      text: "The content of this manuscript is clear, concise, and easily understood by readers (i.e: easily understood by readers up to 6th grade).",
    },
    orange: {
      min: 50,
      max: 79,
      text: "Readers may find some portions of this manuscript challenging to understand (i.e: easily understood by readers up to 12th grade).",
    },
    red: {
      min: 0,
      max: 49,
      text: "This manuscript is dense and complex, making it difficult for readers to comprehend the content conveyed (i.e: best understood by university graduates).",
    },
  },
  score: {
    greenHigh: {
      min: 90,
      max: 100,
      text: "Excellent - The manuscript excels in this area, demonstrating exceptional quality that will likely attract a wide readership and lead to high sales.",
    },
    green: {
      min: 65,
      max: 89,
      text: "Good - The manuscript is strong in this area, with engaging elements that will likely appeal to readers.",
    },
    orange: {
      min: 50,
      max: 64,
      text: "Average - The manuscript meets the basic requirements in this area but doesn't stand out. Will require a considerable amount of editing effort.",
    },
    red: {
      min: 30,
      max: 49,
      text: "Below Average - The manuscript has some elements in this area, but they are not well-developed or engaging.",
    },
    redLow: {
      min: 0,
      max: 29,
      text: "Poor - The manuscript significantly lacks in this area and requires substantial improvement.",
    },
  },
};

export default constants;
