import styled from "styled-components";

export const DetailsWrapper = styled.div`
  width: 100%;
`;

export const AttributesWrapper = styled.dl`
  margin-left: var(--spacing--medium);
  margin-top: var(--spacing--medium);
  font-size: var(--font-size--medium);
  font-weight: 400;
  color: var(--color--grey-dark);
  flex-grow: 1;

  dt {
    font-weight: 600;
    margin-top: var(--spacing--small);
    margin-bottom: var(--spacing--xsmall);
    color: var(--color--primary);
  }

  dd {
    margin-bottom: var(--spacing--small);

    &.large {
      font-size: var(--font-size--large);
      font-weight: 600;
    }

    &.isbn {
      font-family: var(--font-family--monospace);
    }
  }
`;

export const BiblioContent = styled.div`
  margin: var(--spacing--medium) auto;
  width: 100%;
  display: grid;

  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: var(--spacing--medium);
`;

export const KPIs = styled.section`
  margin: var(--spacing--medium) auto;
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(23%, 1fr));
  grid-gap: var(--spacing--medium);
  margin-bottom: var(--spacing--large);
`;

export const DebugBox = styled.div`
  margin: var(--spacing--medium) auto;
  width: 100%;
  color: var(--color--debug);
  margin-bottom: var(--spacing--large);
  background-color: white;
  border: 1px solid var(--color--debug);
  border-radius: 10px;
  padding: 20px;
`;
