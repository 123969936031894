import { styled } from "styled-components";
import { Heading } from "@harpercollins/harpercollins-design-system";
import ScoreBadge from "../../components/ScoreBadge";
import { DetailedAnalysisResult } from "../../../types";
import ExpandableTable from "./ExpandableTable";
import { trafficLightRanges } from "../../../../constants";
import { getTrafficLightText } from "../../../../utils/utils";

interface PlagiarismProps {
  plagiarism: DetailedAnalysisResult["originality"]["plagiarism"];
}

const Wrapper = styled.div`
  width: 100%;
`;

const PlagiarismSummary = styled.div`
  background-color: var(--color--grey-lightest);
  padding: var(--spacing--medium);
  border-radius: var(--border-radius--small);
  margin-bottom: var(--spacing--large);
`;

const PlagiarismHeading = styled.div`
  display: flex;
  align-items: baseline;

  h2 {
    margin-bottom: 0;
  }
`;

function Plagiarism({ plagiarism }: PlagiarismProps) {
  if (!plagiarism) {
    return <div>Plagiarism free report not available.</div>;
  }
  return (
    <Wrapper>
      <PlagiarismSummary>
        <PlagiarismHeading>
          <ScoreBadge
            value={plagiarism?.total_score}
            format="percentage"
            trafficLightRanges={trafficLightRanges.plagiarismFree}
          />
          <p>
            {getTrafficLightText(
              plagiarism?.total_score,
              trafficLightRanges.plagiarismFree,
            )}
          </p>
        </PlagiarismHeading>
      </PlagiarismSummary>
      {Array.isArray(plagiarism?.results) && plagiarism?.results.length > 0 ? (
        <>
          <Heading as="h3">
            {plagiarism.results.length} matched phrases from{" "}
            {plagiarism.sources} sources
          </Heading>
          <p>The following extracts could have been plagiarised:</p>
        </>
      ) : (
        <div>No plagiarism found</div>
      )}
      <ExpandableTable plagiarism={plagiarism} />
    </Wrapper>
  );
}

export default Plagiarism;
