import { styled } from "styled-components";
import { useState } from "react";
import { Icon } from "@harpercollins/harpercollins-design-system";
import {
  faChevronUp,
  faChevronDown,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { DetailedAnalysisResult } from "../../../types";
import ScoreBadge from "../../components/ScoreBadge";
import { trafficLightRanges } from "../../../../constants";

interface ExpandableRowProps {
  data: DetailedAnalysisResult["originality"]["plagiarism"]["results"][0];
  index: number;
}

interface TableProps {
  plagiarism: DetailedAnalysisResult["originality"]["plagiarism"];
}

const ExpandableTable = styled.table`
  width: 100%;
  thead {
    font-weight: var(--font-weight--bold);
  }
  th,
  td {
    vertical-align: middle;
    padding: var(--spacing--medium);
    font-size: var(--font-size--small);
  }
  tbody tr {
    padding: var(--spacing--medium)
    background-color: white;
  }
  tbody tr:not(.expandable) {
    cursor: pointer;
    box-shadow: var(--box-shadow--medium);
    border-radius: var(--border-radius--small);
    &:hover {
      background:var(--color--grey-lighter);
    }
  }
  td {
    padding: var(--spacing--medium);
  }
  .align-center {
    text-align:center;
  }
  .score-badge {
    margin:auto;
  }
  .expandable {
    display: none;
    &.open {
      display: table-row;
    }

    @media print {
      display:  table-row;
    }
  }
`;

const PlagiarismLinks = styled.ul`
  display: flex;
  flex-direction: column;
  li {
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: row;
    margin-bottom: var(--spacing--medium);
    padding-bottom: var(--spacing--small);
    border-bottom: 1px solid var(--color--grey-borders);
    div {
      margin-bottom: var(--spacing--small);
    }
    small {
      color: var(--color--grey);
    }
  }
`;

export const MatchText = styled.div`
  > span {
    background-color: var(--color--grey-lighter);
    padding: var(--spacing--xsmall);
    font-style: italic;
  }
`;

const IconLink = styled.a`
  margin-left: auto;
  padding: var(--spacing--medium);
`;

const InlineContent = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing--medium);
  margin-bottom: var(--spacing--medium);
`;

const ToggleButton = styled.button`
  background-color: var(--color--grey-lighter);
  border-style: none;
  padding: var(--spacing--medium) var(--spacing--medium);
  font-size: var(--font-size--large);
  width: 3rem;
  height: 3rem;
  margin-left: auto;
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--color--primary);
  border-radius: 50%;
`;

function ExpandableRow({ data, index }: ExpandableRowProps) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const toggleExpanded = () => setExpanded(!expanded);

  const number = index + 1;
  return (
    <>
      <tr onClick={toggleExpanded}>
        <td width="20px">{number}.</td>
        <td>
          <MatchText>
            <span>{data.phrase}</span>
          </MatchText>
        </td>
        <td className="align-center" aria-label="Average score (percentage)">
          <ScoreBadge
            value={data.avg_score}
            format="percentage"
            size="small"
            trafficLightRanges={trafficLightRanges.plagiarismFree}
          />
        </td>
        <td className="align-center">{data.matches.length}</td>
        <td>
          <ToggleButton>
            {expanded ? (
              <Icon icon={faChevronUp} />
            ) : (
              <Icon icon={faChevronDown} />
            )}
          </ToggleButton>
        </td>
      </tr>

      <tr className={`expandable ${expanded ? "open" : ""}`}>
        <td colSpan={5}>
          <PlagiarismLinks>
            {Array.isArray(data.matches) && data.matches.length > 0 ? (
              data.matches.map((match) => (
                <li key={match.website}>
                  <InlineContent>
                    <div>
                      <MatchText>
                        <span>{match.pText}</span>
                      </MatchText>
                      <a href={match.website} target="_blank" rel="noreferrer">
                        {match.website}
                      </a>
                    </div>
                  </InlineContent>
                  <IconLink href={match.website} target="_blank">
                    <Icon icon={faArrowUpRightFromSquare} />
                  </IconLink>
                </li>
              ))
            ) : (
              <div className="align-center">No links found</div>
            )}
          </PlagiarismLinks>
        </td>
      </tr>
    </>
  );
}

function Table({ plagiarism }: TableProps) {
  return (
    <ExpandableTable>
      <thead>
        <tr>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <th> </th>
          <th>Phrase</th>
          <th className="align-center">Average Score</th>
          <th className="align-center">Matched Websites</th>
        </tr>
      </thead>
      <tbody>
        {Array.isArray(plagiarism?.results) && plagiarism.results.length > 0 ? (
          <>
            {plagiarism.results.map((result, index) => (
              <ExpandableRow data={result} key={result.phrase} index={index} />
            ))}
          </>
        ) : (
          <tr>
            <td colSpan={5} className="align-center">
              No plagiarised content found
            </td>
          </tr>
        )}
      </tbody>
    </ExpandableTable>
  );
}

export default Table;
