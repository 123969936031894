/* eslint-disable react/require-default-props */

import React, { ReactElement } from "react";
import styled from "styled-components";
import { OverlayContext } from "@django-render/core";
import { Button, Heading } from "@harpercollins/harpercollins-design-system";

const FormActionsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  margin-bottom: 40px;
  margin-top: 40px;
`;

function CompetitorAfterCreateView(): ReactElement {
  const { requestClose } = React.useContext(OverlayContext);

  return (
    <div>
      <Heading as="h2">Competitor create</Heading>

      <FormActionsWrapper>
        <Button
          type="button"
          kind="secondary"
          onClick={(e) => {
            e.preventDefault();
            requestClose({ skipDirtyFormCheck: true });
          }}
        >
          Close
        </Button>
      </FormActionsWrapper>
    </div>
  );
}
export default CompetitorAfterCreateView;
