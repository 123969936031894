import ReactMarkdown from "react-markdown";

interface LongSummaryProps {
  longSummary: string;
}

function LongSummary({ longSummary }: LongSummaryProps) {
  return (
    <div>
      {longSummary ? (
        <ReactMarkdown>{longSummary}</ReactMarkdown>
      ) : (
        <div>Long Summary not available</div>
      )}
    </div>
  );
}

export default LongSummary;
