import { styled } from "styled-components";
import { Heading } from "@harpercollins/harpercollins-design-system";
import { DetailedAnalysisResult } from "../../../types";

interface CharacterDescriptionsProps {
  characters: DetailedAnalysisResult["characters"]["characters"];
}

const Character = styled.div`
  margin-bottom: var(--spacing--medium);
  padding-bottom: var(--spacing--medium);
  border-bottom: 1px solid var(--color--grey-borders);
`;

function CharacterDescriptions({ characters }: CharacterDescriptionsProps) {
  return (
    <div>
      {Array.isArray(characters) && characters.length > 0 ? (
        characters.map((character) => (
          <Character key={character.name}>
            <Heading as="h3">{character.name}</Heading>
            <p>{character.description}</p>
          </Character>
        ))
      ) : (
        <div>No characters found</div>
      )}
    </div>
  );
}

export default CharacterDescriptions;
