import React, { ReactElement, useContext } from "react";
import { styled } from "styled-components";
import {
  Divider,
  Heading,
  Grid,
  Breadcrumb,
} from "@harpercollins/harpercollins-design-system";
import { Link } from "@django-render/core";
import BaseLayout from "../../../lib/components/BaseLayout";
import { URLsContext } from "../../../contexts";

const StyledGrid = styled(Grid)`
  &&& {
    margin: 0 auto;
  }

  ${Divider} {
    margin: 2rem 0;
  }
  img {
    width: 100%;
    max-width: 500px;
    height: auto;
    margin: 1rem 0;
  }
  section li {
    font-weight: var(--font-weight--bold);
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacing--medium);
`;

function HelpView(): ReactElement {
  const urls = useContext(URLsContext);
  return (
    <BaseLayout>
      <HeaderWrapper>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link href={urls.manuscript_list}>← Manuscripts</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Help</Breadcrumb.Item>
        </Breadcrumb>
      </HeaderWrapper>
      <StyledGrid container spacing={2} maxWidth="md">
        <Grid xs={12}>
          <Heading as="h1">Help</Heading>
          <p>
            This help page provides instructions on how to use the ScriptScan
            application.
          </p>
          <ul>
            <li>
              <a href="#uploading-a-new-manuscript">
                Uploading a new manuscript
              </a>
            </li>
            <li>
              <a href="#walk-through-of-the-analysis-metrics">
                A walk-through of the Analysis metrics
              </a>
            </li>
            <li>
              <a href="#export-of-analysis">Export of Analysis</a>
            </li>
            <li>
              <a href="#retention-policy">Retention policy</a>
            </li>
          </ul>
          <Divider />
          <section id="uploading-a-new-manuscript">
            <Heading as="h2">Uploading a new manuscript</Heading>
            <p>
              Scriptscan is designed to ingest manuscript files in PDF, .docx or
              .txt format. Nb the max file size is 10MB. Once ingested, the AI
              analysis can be run to provide a detailed summary of the
              manuscripts plot, characters and present similar titles based on
              the content. It will also run checks to assess for plagiarism and
              AI-generated content.
            </p>

            <ol>
              <li>
                From the homepage, click 'Add Manuscript' and enter the title,
                author, and category to best describe the genre of the
                manuscript.
              </li>
              <li>
                Then 'drag and drop' the file or navigate to a location on your
                laptop to upload.
              </li>
              <li>
                You will only be able to view manuscripts that you have uploaded
                OR manuscripts that have been shared with a team you are part
                of. To allow other team members to see the manuscript you are
                about to ingest, choose your team from the team drop down. If
                you wish to create a new team or add/remove members from a team
                - please contact{" "}
                <a href="mailto:analytics@harpercollins.co.uk">
                  analytics@harpercollins.co.uk
                </a>{" "}
                who can help.
              </li>
              <li>
                You can also add a Project name if you want to classify a set of
                manuscripts. I.e. you can add the same project names as used in
                the Submittable platform.
              </li>
              <li>Finally click 'Run Analysis'.</li>
            </ol>
          </section>
          <Divider />
          <section id="walk-through-of-the-analysis-metrics">
            <Heading as="h2">A walk-through of the Analysis metrics</Heading>
            <p>
              Once the analysis is complete, the manuscript will appear on the
              homepage, with a green tick under the 'Status' column. Your input
              manuscript details (i.e. name, author or category) can be amended
              via the 'edit' button under 'Actions' if necessary.
            </p>
            <ol>
              <li>
                Click on the title or 'view' button to open the analysis page.
              </li>
              <li>
                A brief summary of the manuscript appears at the top of the
                page, with % scores for readability, plagiarism-free and AI
                generated-free. The tabs below the scores provide more detail
                about the manuscript:
              </li>
              <ul>
                <li>
                  Short summary: A brief synopsis of the manuscript - approx.
                  500 words.
                </li>
                <li>
                  Long summary: A more detailed description of the content,
                  approx. 1000 words.
                </li>
                <li>
                  Scoring: The manuscript is 'scored' using an AI Large Language
                  Model (LLM) to provide a % for potential commercial success,
                  originality, character development, writing style, pace,
                  emotional impact, relevance to target audience & lasting
                  impact. A justification for the given score is provided and
                  areas of possible improvement suggested. 'Info spots' next to
                  each measure explain what the LLM is analysing against.
                </li>
                <li>
                  Competitors: This tab displays a list of titles where the AI
                  LLM has identified similar themes or content to the analysed
                  manuscript. These competitors can be across all publishers but
                  must have appeared in the TCM in the last 4 years and sold
                  more than c3000 copies. Inappropriate competitor suggestions
                  can be removed from the list by clicking the bin icon under
                  Actions.
                </li>
              </ul>
              <p>
                If there are competing titles you wish to include that have not
                been suggested, you can add these via the 'Add Competitors'
                button. Input a title or author name to search for bestsellers
                across any publisher. Results will appear in the dropdown. Note
                - they must have sold well in the last 4 years to appear.
              </p>
              <ul>
                <li>
                  Character Descriptions: Provides an overview of each of the
                  characters and their role in the plot of manuscript.
                </li>
                <li>
                  Metadata: Suggests genre classifications, BISAC descriptions &
                  possible keywords for the book and Amazon searches.
                </li>
                <li>
                  Bibliographic: Details when and by whom the manuscript was
                  uploaded, it's source and a link to the original file.
                  Manuscripts will be deleted after 90 days from the date
                  submitted.
                </li>
                <li>
                  AI-Free: Shows the % of certainty as to whether the manuscript
                  has been written by a human and not ai-generated.
                </li>
                <li>
                  Plagiarism-free: Shows the % of certainty that the manuscript
                  is not the work of someone else, passed off as their own.
                </li>
              </ul>
            </ol>
          </section>
          <Divider />
          <section id="export-of-analysis">
            <Heading as="h2">Export Analysis</Heading>
            <p>
              To download the detailed manuscript analysis as a PDF or send to a
              printer - click the 'Export Analysis' button in the top right of
              the manuscript analysis page. It will default to your linked
              printer. For PDF, click the 'destination' drop down and choose
              'save as PDF'. If you do not wish to export the entire analysis,
              select 'custom' under the 'Pages' drop down and choose the ones
              you want.
            </p>
          </section>
          <Divider />
          <section id="retention-policy">
            <Heading as="h2"> Retention Policy </Heading>
            <p>
              Any content uploaded or data input through ScriptScan is not used
              to train any AI models at any point in the process and it is
              automatically removed from our databases after 90 days from being
              submitted.
            </p>
          </section>
        </Grid>
      </StyledGrid>
    </BaseLayout>
  );
}

export default HelpView;
