// display file size in human readable format

import { TrafficLightRange, TrafficLights } from "../manuscripts/types";

// eslint-disable-next-line import/prefer-default-export
export function bytesToSize(bytes: number): string {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "n/a";
  const i = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes.length - 1,
  );
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}
// calculate percentage of a value
export const calculatePercentage = (value: number) => Math.round(value * 100);

// get traffic light text based on score and ranges
export const getTrafficLightText = (
  score: number,
  ranges: TrafficLights,
): string => {
  const trafficLightText = Object.values(ranges).filter(
    (range: TrafficLightRange) =>
      Math.round(score) >= range.min && score <= range.max,
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  return trafficLightText[0]?.text || "";
};
