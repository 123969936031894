/* eslint-disable react/require-default-props */

import React, { ReactElement } from "react";
import { Form, OverlayContext } from "@django-render/core";
import styled from "styled-components";
import { Button, Heading } from "@harpercollins/harpercollins-design-system";

const FormActionsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  margin-bottom: 40px;
  margin-top: 40px;
`;

const MessageWrapper = styled.div`
  p {
    margin-top: 1rem;
  }
`;

interface ConfirmDeleteViewContext {
  csrf_token: string;
  next?: string;
  title: string;
  message_html: string;
  action_url: string;
}

function ConfirmDeleteView({
  csrf_token,
  next,
  title,
  message_html,
  action_url,
}: ConfirmDeleteViewContext): ReactElement {
  const { requestClose } = React.useContext(OverlayContext);

  return (
    <div>
      <Heading as="h2">{title}</Heading>

      {/* eslint-disable-next-line react/no-danger */}
      <MessageWrapper dangerouslySetInnerHTML={{ __html: message_html }} />

      <Form action={action_url} method="post" noValidate>
        <input type="hidden" name="csrfmiddlewaretoken" value={csrf_token} />
        {!!next && <input type="hidden" name="next" value={next} />}

        <FormActionsWrapper>
          <Button type="submit" kind="danger">
            Delete
          </Button>
          <Button
            type="button"
            kind="secondary"
            onClick={(e) => {
              e.preventDefault();
              requestClose({ skipDirtyFormCheck: true });
            }}
          >
            Cancel
          </Button>
        </FormActionsWrapper>
      </Form>
    </div>
  );
}
export default ConfirmDeleteView;
