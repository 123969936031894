import React, { ReactElement, SyntheticEvent } from "react";
import { FormWidgetChangeNotificationContext } from "@django-render/core";
import { Autocomplete } from "@harpercollins/harpercollins-design-system";
import { AutocompleteProps } from "@mui/joy";
import { WidgetDef } from "./base";
import { BaseOption } from "../../../manuscripts/types";

interface AutoCompleteWrapperProps<T extends BaseOption>
  extends AutocompleteProps<T, boolean, boolean, boolean> {
  id: string;
  name: string;
  disabled: boolean;
  className: string;
  onChange?: (event: SyntheticEvent<Element, Event>) => void;
  options: T[];
  label?: string;
}

function AutoCompleteWrapper<T extends BaseOption>({
  onChange: originalOnChange,
  options,
  ...props
}: AutoCompleteWrapperProps<T>) {
  const changeNotification = React.useContext(
    FormWidgetChangeNotificationContext,
  );

  return (
    <Autocomplete
      {...props}
      label=""
      defaultValue={props.defaultValue as T}
      options={options}
      onChange={(event: SyntheticEvent<Element, Event>) => {
        if (originalOnChange) {
          originalOnChange(event);
        }
        if (changeNotification) {
          changeNotification();
        }
      }}
    />
  );
}

export default class AutocompleteDef implements WidgetDef {
  idForLabel: string;

  choices: BaseOption[];

  className: string;

  constructor(idForLabel: string, choices: BaseOption[], className: string) {
    this.idForLabel = idForLabel;
    this.choices = choices;
    this.className = className;
  }

  render(
    id: string,
    name: string,
    disabled: boolean,
    value: string,
  ): ReactElement {
    // Find the option that corresponds to the value
    // HACK: Cast null value to and empty string as that's that value Django uses for the empty value of a ModelChoiceField
    // Also cast ints to string as Django may use both interchangably for model primary keys
    const option = this.choices.find(
      (choice) => `${choice.value}` === `${value || ""}`,
    );

    return (
      <AutoCompleteWrapper
        id={id}
        name={name}
        defaultValue={option}
        options={this.choices}
        disabled={disabled}
        className={this.className}
      />
    );
  }

  getIdForLabel(id: string): string {
    return this.idForLabel.replace("__ID__", id);
  }
}
