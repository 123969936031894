/* eslint-disable react/require-default-props */

import React, { ReactElement } from "react";
import styled from "styled-components";
import { Button, Logo } from "@harpercollins/harpercollins-design-system";
import { Form } from "@django-render/core";
import FormDef from "../../forms/adapters/Form";

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(25, 42, 60);
  height: 100vh;
`;

export const FooterWrapper = styled.footer`
  position: fixed;
  bottom: 0;
  margin-bottom: 10px;

  svg {
    width: 100%;
    height: auto;
    max-width: 500px;
  }
`;

const LoginWrapper = styled.div`
  width: 24rem;
  border-radius: var(--border-radius--medium);
  padding: 2.5rem;
  background-color: white;

  h2 {
    color: var(--color--grey-dark);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const OktaSignIn = styled.div`
  padding: 1rem;
  padding-bottom: 1.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--color--grey-light);

  a:hover {
    text-decoration: none;
  }
`;

const Space = styled.div`
  flex-grow: 1;
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

interface LoginViewContext {
  csrf_token: string;
  form: FormDef;
  action_url: string;
  okta_url: string;
}

function LoginView({
  csrf_token,
  form,
  action_url,
  okta_url,
}: LoginViewContext): ReactElement {
  return (
    <Wrapper>
      <Space />
      <LoginWrapper>
        <h2>Sign in to ScriptScan</h2>

        {okta_url && (
          <OktaSignIn>
            <Button type="link" href={okta_url} kind="primary">
              Sign in with Okta
            </Button>
          </OktaSignIn>
        )}

        <Form action={action_url} method="post" noValidate>
          <input type="hidden" name="csrfmiddlewaretoken" value={csrf_token} />

          {form.render({ hideRequiredAsterisks: true })}

          <SubmitButtonWrapper>
            <Button type="submit">Log in</Button>
          </SubmitButtonWrapper>
        </Form>
      </LoginWrapper>
      <Space />
      <FooterWrapper>
        <Logo />
      </FooterWrapper>
    </Wrapper>
  );
}
export default LoginView;
