import ReactMarkdown from "react-markdown";

interface ShortSummaryProps {
  shortSummary: string;
}

function ShortSummary({ shortSummary }: ShortSummaryProps) {
  return (
    <div>
      {shortSummary ? (
        <ReactMarkdown>{shortSummary}</ReactMarkdown>
      ) : (
        <div>Short Summary not available</div>
      )}
    </div>
  );
}

export default ShortSummary;
