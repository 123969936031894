import React, {
  useState,
  useEffect,
  HTMLAttributes,
  SyntheticEvent,
} from "react";
import {
  Chip,
  ListItemButton,
  AutocompleteSelectMultiple,
  Heading,
  useFetch,
} from "@harpercollins/harpercollins-design-system";
import styled from "styled-components";

interface IOption {
  value: string;
  label: string;
  author: string;
  isbn: string;
  format: string;
  cover_image_url: string;
}

const StyledOption = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: var(--spacing--small);
  h3 {
    font-size: var(--font-size--medium);
  }
  img {
    width: 50px;
    height: 70px;
    margin-right: 8px;
  }
  .MuiListItemText-root {
    margin-left: 8px;
  }
`;

function Option(props: { option: IOption }) {
  const { option } = props;

  return (
    <StyledOption key={option.value} {...props}>
      <img src={option?.cover_image_url} alt={option?.label} />
      <div>
        <Heading as="h3">{option?.label}</Heading>
        <div>
          {option?.author} <br />
          {option?.format && <Chip size="sm">{option?.format}</Chip>}
        </div>
      </div>
    </StyledOption>
  );
}

interface SearchCompetitorsProps {
  name: string;
  onChange: (event: SyntheticEvent<Element, Event>) => void;
  searchURL: string;
}

function SearchCompetitors({ name, searchURL }: SearchCompetitorsProps) {
  const [inputValue, setInputValue] = useState<string>("");

  const [fetchParams, setFetchParams] = useState<{
    url: string;
    options?: RequestInit;
    debounce?: number;
  } | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unused-vars
  const debounce = 500;
  const { data, loading } = useFetch(
    fetchParams?.url || "",
    fetchParams?.options,
    debounce,
  ) as {
    data: {
      competitors: IOption[] | undefined;
    };
    loading: boolean;
    error: Error | null;
  };

  useEffect(() => {
    if (inputValue) {
      const newUrl = `${searchURL}?search_string=${inputValue}`;
      setFetchParams({ url: newUrl });
    }
  }, [inputValue, searchURL]);

  return (
    <AutocompleteSelectMultiple
      id="search-competitors"
      options={data?.competitors || []}
      placeholder="Search title, author..."
      loading={loading}
      clearOnBlur={false}
      filterSelectedOptions
      noOptionsText={
        inputValue?.length > 0
          ? "No Competitors found. Ensure title is correct and that it has sold in the last 4 years"
          : "No Competitors"
      }
      isOptionEqualToValue={(option, val) => option.value === val.value}
      onInputChange={(
        _event: SyntheticEvent<Element, Event>,
        value: string,
      ) => {
        setInputValue(value);
      }}
      filterOptions={(options) => options}
      inputValue={inputValue}
      helpText="Enter Title or Author to search for other bestsellers from the last 4 years with sales over 3000 units."
      renderOption={(
        props: Omit<HTMLAttributes<HTMLLIElement>, "color">,
        option: IOption,
      ) =>
        (
          <ListItemButton>
            <Option {...props} key={option.value} option={option} />
          </ListItemButton>
        ) as React.ReactElement
      }
      SelectedOption={Option}
      name={name}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      }}
    />
  );
}

export default SearchCompetitors;
