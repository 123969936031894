import React, { useState, useContext } from "react";
import { DirtyFormContext, OverlayContext } from "@django-render/core";
import { Drawer } from "@harpercollins/harpercollins-design-system";

interface ModalWindowProps {
  side: "left" | "right";
}

function ModalWindow({
  children,
  side,
}: React.PropsWithChildren<ModalWindowProps>) {
  const { requestClose, closeRequested, onCloseCompleted } =
    useContext(OverlayContext);
  const { unloadBlocked } = useContext(DirtyFormContext);
  const [open, setOpen] = useState(false);
  React.useEffect(() => {
    setTimeout(() => setOpen(true), 50);
  }, []);

  return (
    <Drawer
      side={side}
      open={open}
      size="md"
      closeBlocked={unloadBlocked}
      onClose={requestClose}
      closeRequested={closeRequested}
      onCloseCompleted={onCloseCompleted}
    >
      {children}
    </Drawer>
  );
}

export default ModalWindow;
