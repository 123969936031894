/* eslint-disable react/require-default-props */

import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { Link, NavigationContext } from "@django-render/core";
import {
  Button,
  Heading,
  Icon,
  Table,
} from "@harpercollins/harpercollins-design-system";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import {
  createColumnHelper,
  ColumnDef,
  CellContext,
} from "@tanstack/react-table";
import HeaderWrapper, {
  HeaderCommands,
  HeaderContent,
  HeaderTitle,
} from "../../../lib/components/HeaderWrapper";
import BaseLayout from "../../../lib/components/BaseLayout";
import { Manuscript, SummaryAnalysisResult } from "../../types";
import ModalWindow from "../../../lib/components/ModalWindow";
import { URLsContext } from "../../../contexts";
import TextInput from "../../../forms/components/widgets/TextInput";
import { SearchVector, SearchQuery } from "../../../utils/search";
import {
  AnalysisScoreCell,
  PotentialCommercialSuccessCell,
  RelativeTimeCell,
  TextCell,
  StatusCell,
} from "../../../lib/components/TableCells/TableCells";

function TitleCell({ row }: { row: { original: Manuscript } }) {
  const { detail_url: detailUrl, title } = row.original;
  return (
    <Link href={detailUrl} target="_blank" rel="noreferrer">
      {title}
    </Link>
  );
}
const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing--small);
`;
function ActionsCell<TData extends Manuscript, TValue>({
  row,
}: CellContext<TData, TValue>): ReactElement {
  const { openOverlay, refreshProps } = React.useContext(NavigationContext);
  const renderModalWindow = React.useCallback(
    (content: ReactNode) => <ModalWindow side="right">{content}</ModalWindow>,
    [],
  );

  return (
    <ButtonGroup>
      <Button
        type="link"
        as={Link}
        size="small"
        kind="secondary"
        href={row.original.detail_url}
      >
        View
      </Button>
      <Button
        type="button"
        size="small"
        kind="secondary"
        onClick={() =>
          openOverlay(row.original.edit_url, renderModalWindow, {
            onClose: () => {
              // Refresh the context when the modal is closed so the changes take effect
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              refreshProps();
            },
          })
        }
      >
        Edit
      </Button>
      <Button
        type="button"
        kind="basic"
        aria-label="Delete manuscript"
        onClick={() =>
          openOverlay(row.original.delete_url, renderModalWindow, {
            onClose: () => {
              // Refresh the context when the modal is closed so the changes take effect
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              refreshProps();
            },
          })
        }
      >
        <Icon colour="grey" icon={faTrashCan} />
      </Button>
    </ButtonGroup>
  );
}
const columnHelper = createColumnHelper<Manuscript<SummaryAnalysisResult>>();

const columns = [
  columnHelper.accessor("title", {
    header: "Title",
    size: 200,
    cell: TitleCell,
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor("author.canonical_name", {
    header: "Author",
    size: 80,
    cell: TextCell,
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor("category.name", {
    header: "Category",
    size: 80,
    cell: TextCell,
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor("project", {
    header: "Project",
    size: 80,
    cell: TextCell,
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor("created_at", {
    header: "Submitted",
    size: 80,
    cell: RelativeTimeCell,
    sortingFn: "datetime",
  }),
  columnHelper.accessor("created_by", {
    header: "Submitted by",
    size: 80,
    cell: TextCell,
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor("expires_at", {
    header: "Expires",
    size: 80,
    cell: RelativeTimeCell,
    sortingFn: "datetime",
  }),
  columnHelper.accessor("last_analysis.result.scoring.overall_score", {
    header: "Analysis Score",
    size: 80,
    cell: AnalysisScoreCell,
    sortingFn: "alphanumeric",
  }),
  columnHelper.accessor(
    "last_analysis.result.scoring.potential_commercial_success",
    {
      header: "Potential Commercial Success",
      size: 80,
      cell: PotentialCommercialSuccessCell,
      sortingFn: "alphanumeric",
    },
  ),
  columnHelper.accessor("last_analysis.status.code", {
    header: "Status",
    size: 40,
    cell: StatusCell,
    sortingFn: "alphanumeric",
  }),
  columnHelper.display({
    id: "actions",
    header: "Actions",
    size: 180,
    cell: ActionsCell,
    enableSorting: false,
  }),
];
interface ManuscriptListViewContext {
  manuscripts: Manuscript[];
}

function ManuscriptListView({
  manuscripts,
}: ManuscriptListViewContext): ReactElement {
  const { openOverlay, refreshProps } = React.useContext(NavigationContext);
  const urls = React.useContext(URLsContext);
  const [searchValue, setSearchValue] = React.useState("");

  // Create a list of search vectors for the manuscripts
  const manuscriptVectors = React.useMemo(
    () =>
      manuscripts.map((manuscript) => ({
        manuscript,
        vector: new SearchVector(manuscript.title),
      })),
    [manuscripts],
  );

  const filteredManuscripts = React.useMemo(() => {
    if (searchValue !== "") {
      // Create a search vector for the query
      const query = new SearchQuery(searchValue);

      // Score each manuscript against the query
      const scores = manuscriptVectors
        .map(({ manuscript, vector }) => ({
          manuscript,
          score: query.score(vector),
        }))
        .filter(({ score }) => score > 0);

      // Order by score
      scores.sort(({ score: a }, { score: b }) => b - a);

      // Return the manuscripts
      return scores.map(({ manuscript }) => manuscript);
    }
    return manuscripts;
  }, [manuscripts, manuscriptVectors, searchValue]);

  const renderModalWindow = React.useCallback(
    (content: ReactNode) => <ModalWindow side="right">{content}</ModalWindow>,
    [],
  );

  return (
    <BaseLayout>
      <HeaderWrapper>
        <HeaderTitle>
          <Heading as="h1">Manuscripts</Heading>
          <HeaderCommands>
            <Button
              type="button"
              kind="primary"
              onClick={(e) => {
                e.preventDefault();
                openOverlay(urls.manuscript_create, renderModalWindow, {
                  onClose: () => {
                    // Refresh the context when the modal is closed so the new manuscript is visible
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    refreshProps();
                  },
                });
              }}
            >
              <Icon icon={faPlusCircle} />
              Add Manuscript
            </Button>
          </HeaderCommands>
        </HeaderTitle>

        <HeaderContent>
          <p>
            Find a detailed analysis for any manuscript that you want to
            analyse. Analysis will last 90 days and all data will be deleted to
            be in line with Legal advice. Visit the{" "}
            <Link href={urls.help}>help page</Link> for instructions on getting
            started. Contact{" "}
            <a href="mailto:analytics@harpercollins.co.uk">
              analytics@harpercollins.co.uk
            </a>{" "}
            if you have any questions or issues.
          </p>
        </HeaderContent>
        <TextInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search"
        />
      </HeaderWrapper>

      <Table
        id="manuscripts-list"
        columns={columns as ColumnDef<unknown, unknown>[]}
        data={filteredManuscripts}
        pageable={false}
        maxHeight={false}
        // pageCount={}
        // pageSize={}
        // previous={}
        // totalCount={}
      />
    </BaseLayout>
  );
}
export default ManuscriptListView;
