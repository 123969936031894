import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Icon, Tooltip } from "@harpercollins/harpercollins-design-system";

function InfoTooltip({ title }: { title: string }) {
  return (
    <Tooltip placement="top" title={title}>
      <Icon icon={faInfoCircle} colour="grey" size="small" />
    </Tooltip>
  );
}

export default InfoTooltip;
