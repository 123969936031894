import { styled } from "styled-components";
import { Badge, Heading } from "@harpercollins/harpercollins-design-system";

import { DetailedAnalysisResult } from "../../../types";

interface MetaDataProps {
  metadata: DetailedAnalysisResult["metadata"];
}

const MetadataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacing--small);
  margin-top: var(--spacing--medium);
  margin-bottom: var(--spacing--medium);
  padding-bottom: var(--spacing--medium);
  border-bottom: 1px solid var(--color--grey-borders);
`;

interface MetadataGroupProps {
  data: {
    title: string;
    data: string[];
  }[];
}

function MetadataGroup({ data }: MetadataGroupProps) {
  if (!Array.isArray(data) || data.length === 0) {
    return <div>Metadata not available</div>;
  }

  return (
    <>
      {data.map((d) => (
        <div key={d.title}>
          <Heading as="h3">{d.title}</Heading>
          {Array.isArray(d.data) && d.data.length > 0 ? (
            <Group>
              {d.data.map((value) => (
                <div key={value}>
                  <Badge size="medium">{value}</Badge>
                </div>
              ))}
            </Group>
          ) : (
            <div>No '{d.title}' found</div>
          )}
        </div>
      ))}
    </>
  );
}

function Metadata({ metadata }: MetaDataProps) {
  const data = [
    {
      title: "Genres",
      data: metadata?.genres,
    },
    {
      title: "BISAC Descriptions",
      data: metadata?.bisac_descriptions,
    },
    {
      title: "Amazon UK Keywords",
      data: metadata?.keywords_amazon_uk,
    },
    {
      title: "Book Keywords",
      data: metadata?.keywords_book,
    },
  ];
  return (
    <MetadataWrapper>
      <MetadataGroup data={data} />
    </MetadataWrapper>
  );
}

export default Metadata;
