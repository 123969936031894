/* eslint-disable react/require-default-props */

import React, { ReactElement } from "react";
import { styled } from "styled-components";
import { Link } from "@django-render/core";
import { Heading } from "@harpercollins/harpercollins-design-system";
import BaseLayout from "../../../lib/components/BaseLayout";
import { URLsContext } from "../../../contexts";

const Wrapper = styled.div`
  padding: 1rem;

  a {
    margin-top: 1rem;
  }
`;

interface NotFoundViewContext {
  message?: string;
}

function NotFoundView({ message }: NotFoundViewContext): ReactElement {
  const urls = React.useContext(URLsContext);

  return (
    <BaseLayout>
      <Wrapper>
        <Heading as="h2">Not Found</Heading>
        {message && <p>{message}</p>}
        <Link href={urls.manuscript_list}>Go back to manuscript listing</Link>
      </Wrapper>
    </BaseLayout>
  );
}

export default NotFoundView;
