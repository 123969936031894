import React, { useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { DirtyFormContext } from "@django-render/core";
import { Button, Icon } from "@harpercollins/harpercollins-design-system";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const ContainerWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 1000px) {
  }

  @media only screen and (max-width: 650px) {
  }
`;

const slideInUnsavedChangesWarning = keyframes`
    from {
      height: 0px;
    }

    to {
      height: 50px;
    }
`;

const UnsavedChangesWarningOuterWrapper = styled.div`
  width: 100%;
  background-color: #ffdadd;
  overflow: hidden;
  margin-top: 0;
  animation: ${slideInUnsavedChangesWarning} 0.5s ease;
  display: flex;
`;

const UnsavedChangesWarningInnerWrapper = styled(ContainerWrapper)`
  height: 50px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 20px;
  color: var(--color--indigo);
  font-size: 15px;
  font-weight: var(--font-weight--bold);
  padding-top: 0;
  padding-bottom: 0;
  justify-content: center;

  svg {
    color: var(--color--red);
    height: 18px;
  }

  b {
    font-weight: var(--font-weight--bold);
  }
`;

const Container = React.forwardRef<
  HTMLDivElement,
  React.InputHTMLAttributes<HTMLDivElement>
>(
  (
    { children, ...props }: React.InputHTMLAttributes<HTMLDivElement>,
    ref,
  ): React.ReactElement => {
    const { unloadRequested, confirmUnload } =
      React.useContext(DirtyFormContext);

    const warningRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (unloadRequested && warningRef?.current) {
        warningRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    });

    return (
      <>
        {unloadRequested && (
          <UnsavedChangesWarningOuterWrapper ref={warningRef}>
            <UnsavedChangesWarningInnerWrapper
              role="alert"
              aria-live="assertive"
            >
              <Icon icon={faExclamationTriangle} />
              <p>
                <b>You have unsaved changes.</b> Please save your changes before
                leaving.
              </p>
              <Button
                type="button"
                size="small"
                onClick={(e) => {
                  e.preventDefault();
                  confirmUnload();
                }}
              >
                Leave without saving
              </Button>
            </UnsavedChangesWarningInnerWrapper>
          </UnsavedChangesWarningOuterWrapper>
        )}
        <ContainerWrapper ref={ref} {...props}>
          {children}
        </ContainerWrapper>
      </>
    );
  },
);

export default Container;
