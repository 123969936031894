import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { enableMapSet } from "immer";
import { FullPageErrorFallback } from "@harpercollins/harpercollins-design-system";
import App from "./App";
import { sentryErrorMonitoring } from "./errors/sentry";

sentryErrorMonitoring();
enableMapSet();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<FullPageErrorFallback />}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
