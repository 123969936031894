/* eslint-disable react/require-default-props */

import React, { ReactElement } from "react";
import styled from "styled-components";
import { Button, Heading } from "@harpercollins/harpercollins-design-system";
import { Form, OverlayContext } from "@django-render/core";
import FormDef from "../../../forms/adapters/Form";

const FormActionsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  margin-bottom: 40px;
  margin-top: 40px;
`;

interface FormViewContext {
  csrf_token: string;
  next?: string;
  title: string;
  submit_button_label: string;
  action_url: string;
  form: FormDef;
}

function FormView({
  csrf_token,
  next,
  title,
  submit_button_label,
  action_url,
  form,
}: FormViewContext): ReactElement {
  const { requestClose } = React.useContext(OverlayContext);

  return (
    <div>
      <Heading as="h2">{title}</Heading>

      <Form action={action_url} method="post" noValidate>
        <input type="hidden" name="csrfmiddlewaretoken" value={csrf_token} />
        {!!next && <input type="hidden" name="next" value={next} />}

        {form.render()}

        <FormActionsWrapper>
          <Button type="submit">{submit_button_label}</Button>
          <Button
            type="button"
            kind="secondary"
            onClick={(e) => {
              e.preventDefault();
              requestClose({ skipDirtyFormCheck: true });
            }}
          >
            Cancel
          </Button>
        </FormActionsWrapper>
      </Form>
    </div>
  );
}
export default FormView;
